import React from "react";
import { graphql } from "gatsby";

import renderPageSections from "utils/renderPageSections";

import Meta from "components/Meta";

const CollectionTemplate = ({ data, previewData }) => {
  const { main, meta } = previewData
    ? previewData.content
    : data.sanityCollection._rawContent;

  return (
    <React.Fragment>
      <Meta {...meta} title={meta && meta.title ? meta.title : main.title} />
      {renderPageSections(main.sections)}
    </React.Fragment>
  );
};

export default CollectionTemplate;

export const query = graphql`
  query sanityCollection($id: String!) {
    sanityCollection(id: { eq: $id }) {
      _rawContent(resolveReferences: { maxDepth: 9 })
    }
  }
`;
